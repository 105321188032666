.call-to-action-amount {
	font-size: 30px;
	color: $red;
	display: block;
	text-align: center;
}

.call-to-action-subtext {
	font-size: 16px !important;
	color: $red;
	display: block;
	text-align: center;
	text-transform: uppercase;
}

.photo-wide-top {
	z-index: 99;
	.photo-frame {
		position: relative;
		width: 100%;
		height: 230px;
		background-size: cover;
		background-position: center center;
		.btn-action {
			display: block;
			position: absolute;
			left: 15px;
			top: -10px;
			width: 150px;
			height: 150px;
			border-radius: 150px;
			background: $white url('../images/visual-call-to-action-twigs.png') center bottom 2rem no-repeat;
			box-shadow: 0 1px 2px rgba(0, 0, 0, .4);
			a {
				display: block;
				padding-top: 28px;
				padding-left: 0px;
				width: 100%;
				font-family: 'Muli', serif;
				font-size:16px;
				color: $taupe-dark;
				letter-spacing: -0.5px;
				line-height: 25px;
				text-align: center;
			}
			span {
				font-size:30px;
			}
		}
		.btn-purple {
			display: block;
			position: absolute;
			right: 20px;
			top: 120px;
			width: 150px;
			height: 150px;
			border-radius: 150px;
			background-color: $red;
			padding-top: 38px;
			padding-left: 0px;
			font-family: 'Muli', serif;
			font-weight: 600;
			color: #fff;
			line-height: 20px;
			text-align: center;
  			font-size: 20px;
  			line-height: 25px;
		}
	}
}



.text-top {
	.inner {
		float: left;
		width: 100%;
		background-color: #fff;
		padding: 0 200px 40px 200px;
	}
	.frame	{
		width: 100%;
		float: left;
		background-color: #fff;
		.left {
			float: left;
			width: 50%;
			padding-right: 20px;
			p {
				text-align: right;
			}

		}
		.right {
			float: left;
			width: 50%;
			padding-left: 20px;
			p {
				text-align: left;
			}

		}
	}
	h1 {
		position: relative;
		left: 50%;
		@include transform(translate(-50%,0));
		display: inline-block;
		text-align: center;
		margin-bottom: 50px;
		margin-top: 30px;
	  	font-size: 24px;
	  	font-family: 'Muli', serif;
	  	color: $red;
	  	text-transform: uppercase;
	}
	h2 {
		font-size: 20px;
	   	color: rgb(74, 93, 97);
	   	text-align: center;
	}
	p {
		font-size: 20px;
  		color: rgb(74, 93, 97);
  		text-align: center;
  		line-height: 25px;
		padding-bottom: 10px;
	}
	a {
		font-size: 20px;
  		color: rgb(74, 93, 97);
  		text-align: center;
  		line-height: 25px;
		padding-bottom: 10px;
		text-decoration: underline;
	}
}

.media-section {
	.media {
		float: left;
		width: 33.3333333333%;
		height: 230px;
		background-size: cover;
		background-position: center center;
	}
}


.banner-info {
	.frame {
		float: left;
		width: 100%;
		padding: 50px 200px 50px 100px;
		border-bottom: 1px solid #8ca1a5;
		background-color: #fff;
		.photo {
			float: left;
			width: 146px;
			height: 146px;
			background-size: cover;
			background-position: center center;
		}
		.textbox {
			float: left;
			max-width: 700px;
			width: 100%;
			padding-left: 50px;
			h2 {
  				font-size: 20px;
				color: rgb(74, 93, 97);
			}
			p,
			a {
  				font-size: 16px;
  				color: rgb(51, 51, 51);
  				line-height: 20px;
			}
		}
	}
}




.ticket {
	.inner {
		float: left;
		width: 100%;
		background-color: #fff;
	}
	.frame {
		position: relative;
		width: 60%;
		margin: 0 auto;
		padding: 40px 0 100px 0;
		p {
		  font-size: 24px;
		  color: rgb(74, 93, 97);
		  text-align: center;
		}
		.btn-ticket-purple {

		}
		.btn-ticket-purple {
	      	position: absolute;
	      	left: 50%;
	      	@include transform(translate(-50%,0));
	      	margin-top: 20px;
	      	margin-right: 40px;
	      	width: 180px;
	      	height: 40px;
	      	border-radius: 2px;
	      	background-color: $red;
	      	box-shadow: 0 2px 2px $taupe-dark;
	        .icon {
	           display: inline-block;
	       	   margin: 12px 20px 0 25px;
	           width: 24px;
	           height: 16px;
	           background-image: url(../images/icon-button-tickets-white.svg);
	        }
	        h2  {
	           position: relative;
	           top: -2px;
	           display: inline-block;
  				font-size: 16px;
  				color: rgb(255, 255, 255);
	        }
	    }
	}
}


input:hover {
	opacity: 0.7;
}

.single-news {
	.inner {
		float: left;
		width: 100%;
		background-color: #fff;
	}
	.textbox {
		margin: 0 auto;
		width: 70%;
		h1 {
			font-size: 30px;
	  		font-family: 'Muli', serif;
	  		color: rgb(129, 157, 152);
	  		text-align: center;
	  		padding-top: 40px;
		}
		h2 {
			font-size: 30px;
	  		text-align: center;
		}
		p,
		li {
			text-align: center;
			font-size: 16px;
			color: rgb(51, 51, 51);
			line-height: 22px;
			padding-bottom: 20px;
			img {
				// width: 60%;
				position: inherit;
				left: inherit;
				@include transform(translate(0%,0));
				margin: 20px 0;
			}
		}
		a {
			font-size: 16px;
			color: rgb(51, 51, 51);
			line-height: 22px;
			padding-bottom: 20px;
			text-decoration: underline;
		}
	}
	img {
		// width: 60%;
		position: relative;
		left: 50%;
		@include transform(translate(-50%,0));
	}
}

.under-construction {
	h1 {
		font-size: 40px;
  		font-family: 'Muli', serif;
  		color: rgb(129, 157, 152);
  		text-align: center;
  		padding-top: 150px;
  		margin-bottom: 200px;
	}
	p,
	li {
		text-align: center;
		font-size: 16px;
		color: rgb(51, 51, 51);
		line-height: 22px;
		padding-bottom: 20px;
		img {
			width: 60%;
			position: inherit;
			left: inherit;
			@include transform(translate(0%,0));
			margin: 20px 0;
		}
	}
}

.brands-section-page {
	.frame {
		position: relative;
		float: left;
		width: 100%;
		padding: 60px 20px;
		background-color: #fff;
		.canvas {
			text-align: center;
		}
	}
	.box {
		position: relative;
		display: inline-block;
		text-align: center;
		width: 380px;
		height: 300px;

		img {
			position: relative;
			top: 50px;
			width: 100%;
			max-width: 250px;
		}
	}
}

/* ==========================================================================
   media styles
   ========================================================================== */


@media screen and (max-width: 1200px) {
	.banner-info {
		.frame {
			float: left;
			width: 100%;
			padding: 50px;
			border-bottom: 1px solid #8ca1a5;
			.photo {
				float: inherit;
				position: relative;
				left: 50%;
				@include transform(translate(-50%,0));
				margin-bottom: 20px;
				width: 146px;
				height: 146px;
				background-size: cover;
				background-position: center center;
			}
			.textbox {
				float: left;
				max-width: 100%;
				width: 100%;
				padding-left: 0px;
				h2 {
	  				font-size: 20px;
					color: rgb(74, 93, 97);
				}
				p,
				a {
	  				font-size: 16px;
	  				color: rgb(51, 51, 51);
	  				line-height: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.ticket {
		.frame {
			width: 90%;
		}
	}
	.text-top .inner {
	    padding: 60px 10px;
	}
	.single-news {
		.textbox {
			width: 90%;
		}
		img {
			width: 95%;
		}
	}
}

@media screen and (max-width: 479px) {

}




