/* v1.0.5 */
/* Core RS CSS file. 95% of time you shouldn't change anything here. */
.royalSlider {
	width: 600px;
	height: 400px;
	position: relative;
	direction: ltr;
}
.royalSlider > * {
	float: left;
}

.rsWebkit3d .rsSlide {
	-webkit-transform: translateZ(0);
}


.rsWebkit3d .rsSlide,
.rsWebkit3d .rsContainer,
.rsWebkit3d .rsThumbs,
.rsWebkit3d .rsPreloader,
.rsWebkit3d img,
.rsWebkit3d .rsOverflow,
.rsWebkit3d .rsBtnCenterer,
.rsWebkit3d .rsAbsoluteEl,
.rsWebkit3d .rsABlock,
.rsWebkit3d .rsLink {
	-webkit-backface-visibility: hidden;
}
.rsFade.rsWebkit3d .rsSlide,
.rsFade.rsWebkit3d img,
.rsFade.rsWebkit3d .rsContainer {
    -webkit-transform: none;
}
.rsOverflow {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	float: left;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}
.rsVisibleNearbyWrap {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	left: 0;
	top: 0;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}
.rsVisibleNearbyWrap .rsOverflow {
	position: absolute;
	left: 0;
	top: 0;

}
.rsContainer {
	position: relative;
	width: 100%;
	height: 100%;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}

.rsArrow,
.rsThumbsArrow {
	cursor: pointer;
}

.rsThumb {
	float: left;
	position: relative;
}


.rsArrow,
.rsNav,
.rsThumbsArrow {
	opacity: 1;
	-webkit-transition:opacity 0.3s linear;
	-moz-transition:opacity 0.3s linear;
	-o-transition:opacity 0.3s linear;
	transition:opacity 0.3s linear;
}
.rsHidden {
	opacity: 0;
	visibility: hidden;
	-webkit-transition:visibility 0s linear 0.3s,opacity 0.3s linear;
	-moz-transition:visibility 0s linear 0.3s,opacity 0.3s linear;
	-o-transition:visibility 0s linear 0.3s,opacity 0.3s linear;
	transition:visibility 0s linear 0.3s,opacity 0.3s linear;
}


.rsGCaption {
	width: 100%;
	float: left;
	text-align: center;
}

/* Fullscreen options, very important ^^ */
.royalSlider.rsFullscreen {
	position: fixed !important;
	height: auto !important;
	width: auto !important;
	margin: 0 !important;
	padding: 0 !important;
	z-index: 2147483647 !important;
	top: 0 !important;
	left: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
}

.royalSlider .rsSlide.rsFakePreloader {
	opacity: 1 !important;
	-webkit-transition: 0s;
	-moz-transition: 0s;
	-o-transition:  0s;
	transition:  0s;
	display: none;
}

.rsSlide {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	overflow: hidden;
	
	height: 100%;
	width: 100%;
}

.royalSlider.rsAutoHeight,
.rsAutoHeight .rsSlide {
	height: auto;
}

.rsContent {
	width: 100%;
	height: 100%;
	position: relative;
}

.rsPreloader {
	position:absolute;
	z-index: 0;	
}

.rsNav {
	-moz-user-select: -moz-none;
	-webkit-user-select: none;
	user-select: none;
}
.rsNavItem {
	-webkit-tap-highlight-color:rgba(0,0,0,0.25);
}

.rsThumbs {
	cursor: pointer;
	position: relative;
	overflow: hidden;
	float: left;
	z-index: 22;
}
.rsTabs {
	float: left;
	background: none !important;
}
.rsTabs,
.rsThumbs {
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}


.rsVideoContainer {
	/*left: 0;
	top: 0;
	position: absolute;*/
	/*width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	float: left;*/
	width: auto;
	height: auto;
	line-height: 0;
	position: relative;
}
.rsVideoFrameHolder {
	position: absolute;
	left: 0;
	top: 0;
	background: #141414;
	opacity: 0;
	-webkit-transition: .3s;
}
.rsVideoFrameHolder.rsVideoActive {
	opacity: 1;
}
.rsVideoContainer iframe,
.rsVideoContainer video,
.rsVideoContainer embed,
.rsVideoContainer .rsVideoObj {
	position: absolute;
	z-index: 50;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
/* ios controls over video bug, shifting video */
.rsVideoContainer.rsIOSVideo iframe,
.rsVideoContainer.rsIOSVideo video,
.rsVideoContainer.rsIOSVideo embed {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding-right: 44px;
}

.rsABlock {
	left: 0;
	top: 0;
	position: absolute;
	z-index: 15;
	
}

img.rsImg {
	max-width: none;
}

.grab-cursor {
	cursor:url(../img/grab.png) 8 8, move; 
}

.grabbing-cursor{ 
	cursor:url(../img/grabbing.png) 8 8, move;
}

.rsNoDrag {
	cursor: auto;
}

.rsLink {
	left:0;
	top:0;
	position:absolute;
	width:100%;
	height:100%;
	display:block;	
	z-index: 20;
	background: url(../img/blank.gif);
}

/******************************
*
*  RoyalSlider Default Skin 
*
*    1. Arrows 
*    2. Bullets
*    3. Thumbnails
*    4. Tabs
*    5. Fullscreen button
*    6. Play/close video button
*    7. Preloader
*    8. Caption
*    
*  Sprite: 'rs-default.png'
*  Feel free to edit anything
*  If you don't some part - just delete it
* 
******************************/


/* Background */
.rsDefault,
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
	background: #151515;
	color: #FFF;
}


/***************
*
*  1. Arrows
*
****************/

.rsDefault .rsArrow {
	height: 100%;
	width: 44px;
	position: absolute;
	display: block;
	cursor: pointer;
	z-index: 21;
}
.rsDefault.rsVer .rsArrow {
	width: 100%;
	height: 44px;
	
}
.rsDefault.rsVer .rsArrowLeft { top: 0; left: 0; }
.rsDefault.rsVer .rsArrowRight { bottom: 0;  left: 0; }

.rsDefault.rsHor .rsArrowLeft { left: 0; top: 0; }
.rsDefault.rsHor .rsArrowRight { right: 0; top:0; }

.rsDefault .rsArrowIcn {		
	width: 32px;
	height: 32px;
	top: 50%;
	left: 50%;
	margin-top:-16px;	
	margin-left: -16px;

	position: absolute;	
	cursor: pointer;	
	background: url('rs-default.png');

	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
	
	border-radius: 2px;
}
.rsDefault .rsArrowIcn:hover {
	background-color: rgba(0,0,0,0.9);
}

.rsDefault.rsHor .rsArrowLeft .rsArrowIcn { background-position: -64px -32px; }
.rsDefault.rsHor .rsArrowRight .rsArrowIcn { background-position: -64px -64px; }

.rsDefault.rsVer .rsArrowLeft .rsArrowIcn { background-position: -96px -32px; }
.rsDefault.rsVer .rsArrowRight .rsArrowIcn { background-position: -96px -64px; }

.rsDefault .rsArrowDisabled .rsArrowIcn { opacity: .2; filter: alpha(opacity=20);  *display: none; }


/***************
*
*  2. Bullets
*
****************/

.rsDefault .rsBullets {
	position: absolute;
	z-index: 35;
	left: 0;
	bottom: 0;
	width: 100%;
	height: auto;
	margin: 0 auto; 

	background: #000;
	background: rgba(0,0,0,0.75);

	text-align: center;
	line-height: 8px;
	overflow: hidden;
}
.rsDefault .rsBullet {
	width: 8px;
	height: 8px;
	display: inline-block;
	*display:inline; 
	*zoom:1;
	padding: 6px 5px 6px;
}
.rsDefault .rsBullet span {
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #777;
	background: rgba(255,255,255,0.5);
}
.rsDefault .rsBullet.rsNavSelected span {
	background-color: #FFF;
}





/***************
*
*  3. Thumbnails
*
****************/

.rsDefault .rsThumbsHor {
	width: 100%;
	height: 72px;
}
.rsDefault .rsThumbsVer {
	width: 96px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
}
.rsDefault.rsWithThumbsHor .rsThumbsContainer {
	position: relative;
	height: 100%;
}
.rsDefault.rsWithThumbsVer .rsThumbsContainer {
	position: relative;
	width: 100%;
}
.rsDefault .rsThumb {
	float: left;
	overflow: hidden;
	width: 96px;
	height: 72px;
}
.rsDefault .rsThumb img {
	width: 100%;
	height: 100%;
}
.rsDefault .rsThumb.rsNavSelected {
	background: #02874a;
}
.rsDefault .rsThumb.rsNavSelected img {
	opacity: 0.3;
	filter: alpha(opacity=30);
}
.rsDefault .rsTmb {
	display: block;
}

/* Thumbnails with text */
.rsDefault .rsTmb h5 {
	font-size: 16px;
	margin: 0;
	padding: 0;
	line-height: 20px;
	color: #FFF;
}
.rsDefault .rsTmb span {
	color: #DDD;
	margin: 0;
	padding: 0;
	font-size: 13px;
	line-height: 18px;
}



/* Thumbnails arrow icons */
.rsDefault .rsThumbsArrow {
	height: 100%;
	width: 20px;
	position: absolute;
	display: block;
	cursor: pointer;	
	z-index: 21;	
	background: #000;
	background: rgba(0,0,0,0.75);
}
.rsDefault .rsThumbsArrow:hover {
	background: rgba(0,0,0,0.9);
}
.rsDefault.rsWithThumbsVer .rsThumbsArrow {
	width: 100%;
	height: 20px;
}
.rsDefault.rsWithThumbsVer .rsThumbsArrowLeft { top: 0; left: 0; }
.rsDefault.rsWithThumbsVer .rsThumbsArrowRight { bottom: 0;  left: 0; }

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft { left: 0; top: 0; }
.rsDefault.rsWithThumbsHor .rsThumbsArrowRight { right: 0; top:0; }

.rsDefault .rsThumbsArrowIcn {		
	width: 16px;
	height: 16px;
	top: 50%;
	left: 50%;
	margin-top:-8px;	
	margin-left: -8px;
	position: absolute;	
	cursor: pointer;	
	background: url('rs-default.png');
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -128px -32px; }
.rsDefault.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -128px -48px; }

.rsDefault.rsWithThumbsVer .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -144px -32px; }
.rsDefault.rsWithThumbsVer .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -144px -48px; }

.rsDefault .rsThumbsArrowDisabled { display: none !important; }

/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
	.rsDefault .rsThumb {
		width: 59px;
		height: 44px;
	}
	.rsDefault .rsThumbsHor {
		height: 44px;
	}
	.rsDefault .rsThumbsVer {
		width: 59px;
	}
}




/***************
*
*  4. Tabs
*
****************/

.rsDefault .rsTabs {
	width: 100%;
	height: auto;
	margin: 0 auto;
	text-align:center;
	overflow: hidden; padding-top: 12px; position: relative;
}
.rsDefault .rsTab {
	display: inline-block;
	cursor: pointer;
	text-align: center;
	height: auto;
	width: auto;
	color: #333;
	padding: 5px 13px 6px;
	min-width: 72px;
	border: 1px solid #D9D9DD;
	border-right: 1px solid #f5f5f5;
	text-decoration: none;

	background-color: #FFF;
	background-image: -webkit-linear-gradient(top, #fefefe, #f4f4f4); 
	background-image:    -moz-linear-gradient(top, #fefefe, #f4f4f4);
	background-image:         linear-gradient(to bottom, #fefefe, #f4f4f4);

	-webkit-box-shadow: inset 1px 0 0 #fff;
	box-shadow: inset 1px 0 0 #fff;

	*display:inline; 
	*zoom:1;
}
.rsDefault .rsTab:first-child {
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.rsDefault .rsTab:last-child { 
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;

	border-right:  1px solid #cfcfcf;
}
.rsDefault .rsTab:active { 
	border: 1px solid #D9D9DD;   
	background-color: #f4f4f4;
	box-shadow:  0 1px 4px rgba(0, 0, 0, 0.2) inset;
}
.rsDefault .rsTab.rsNavSelected { 
	color: #FFF;
	border: 1px solid #999;
	text-shadow: 1px 1px #838383;
	box-shadow: 0 1px 9px rgba(102, 102, 102, 0.65) inset;
	background: #ACACAC;
	background-image: -webkit-linear-gradient(top, #ACACAC, #BBB);
	background-image: -moz-llinear-gradient(top, #ACACAC, #BBB);
	background-image: linear-gradient(to bottom, #ACACAC, #BBB);
}





/***************
*
*  5. Fullscreen button
*
****************/

.rsDefault .rsFullscreenBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 22;
	display: block;
	position: absolute;
	cursor: pointer;
	
}
.rsDefault .rsFullscreenIcn {
	display: block;
	margin: 6px;
	width: 32px;
	height: 32px;

	background: url('rs-default.png') 0 0;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
	border-radius: 2px;

}
.rsDefault .rsFullscreenIcn:hover {
	background-color: rgba(0,0,0,0.9);
}
.rsDefault.rsFullscreen .rsFullscreenIcn {
	background-position: -32px 0;
}





/***************
*
*  6. Play/close video button
*
****************/

.rsDefault .rsPlayBtn {
	-webkit-tap-highlight-color:rgba(0,0,0,0.3);
	width:64px;
	height:64px;
	margin-left:-32px;
	margin-top:-32px;
	cursor: pointer;
}
.rsDefault .rsPlayBtnIcon {
	width:64px;
	display:block;
	height:64px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	
	-webkit-transition: .3s;
	-moz-transition: .3s;
	transition: .3s;

	background:url(rs-default.png) no-repeat 0 -32px;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
}
.rsDefault .rsPlayBtn:hover .rsPlayBtnIcon {
	background-color: rgba(0,0,0,0.9);
}
.rsDefault .rsBtnCenterer {
	position:absolute;
	left:50%;
	top:50%;
}
.rsDefault .rsCloseVideoBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 500;
	position: absolute;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	
}
.rsDefault .rsCloseVideoBtn.rsiOSBtn {
	top: -38px;
	right: -6px;
}

.rsDefault .rsCloseVideoIcn {
	margin: 6px;
	width: 32px;
	height: 32px;
	background: url('rs-default.png') -64px 0;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
}
.rsDefault .rsCloseVideoIcn:hover {
	background-color: rgba(0,0,0,0.9);
}



/***************
*
*  7. Preloader
*
****************/

.rsDefault .rsPreloader {
	width:20px;
	height:20px;

	left:50%;
	top:50%;
	margin-left:-10px;
	margin-top:-10px;	
}




/***************
*
*  8. Global caption
*
****************/
.rsDefault .rsGCaption {
	position: absolute;
	float: none;
	bottom: 6px;
	left: 6px;
	text-align: left;

	background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.75);

	color: #FFF;
	padding: 2px 8px;
	width: auto;
	font-size: 12px;
	border-radius: 2px;
}


body.stop-scrolling {
  height: 100%;
  overflow: hidden; }

.sweet-overlay {
  background-color: black;
  /* IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  /* IE8 */
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 10000; }

.sweet-alert {
  background-color: white;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 478px;
  padding: 17px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -256px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 99999; }
  @media all and (max-width: 540px) {
    .sweet-alert {
      width: auto;
      margin-left: 0;
      margin-right: 0;
      left: 15px;
      right: 15px; } }
  .sweet-alert h2 {
    color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 25px 0;
    padding: 0;
    line-height: 40px;
    display: block; }
  .sweet-alert p {
    color: #797979;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    position: relative;
    text-align: inherit;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal; }
  .sweet-alert fieldset {
    border: none;
    position: relative; }
  .sweet-alert .sa-error-container {
    background-color: #f1f1f1;
    margin-left: -17px;
    margin-right: -17px;
    overflow: hidden;
    padding: 0 10px;
    max-height: 0;
    webkit-transition: padding 0.15s, max-height 0.15s;
    transition: padding 0.15s, max-height 0.15s; }
    .sweet-alert .sa-error-container.show {
      padding: 10px 0;
      max-height: 100px;
      webkit-transition: padding 0.2s, max-height 0.2s;
      transition: padding 0.25s, max-height 0.25s; }
    .sweet-alert .sa-error-container .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #ea7d7d;
      color: white;
      line-height: 24px;
      text-align: center;
      margin-right: 3px; }
    .sweet-alert .sa-error-container p {
      display: inline-block; }
  .sweet-alert .sa-input-error {
    position: absolute;
    top: 29px;
    right: 26px;
    width: 20px;
    height: 20px;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.1s;
    transition: all 0.1s; }
    .sweet-alert .sa-input-error::before, .sweet-alert .sa-input-error::after {
      content: "";
      width: 20px;
      height: 6px;
      background-color: #f06e57;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      margin-top: -4px;
      left: 50%;
      margin-left: -9px; }
    .sweet-alert .sa-input-error::before {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .sweet-alert .sa-input-error::after {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    .sweet-alert .sa-input-error.show {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1); }
  .sweet-alert input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #d7d7d7;
    height: 43px;
    margin-top: 10px;
    margin-bottom: 17px;
    font-size: 18px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.06);
    padding: 0 12px;
    display: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
    .sweet-alert input:focus {
      outline: none;
      box-shadow: 0px 0px 3px #c4e6f5;
      border: 1px solid #b4dbed; }
      .sweet-alert input:focus::-moz-placeholder {
        transition: opacity 0.3s 0.03s ease;
        opacity: 0.5; }
      .sweet-alert input:focus:-ms-input-placeholder {
        transition: opacity 0.3s 0.03s ease;
        opacity: 0.5; }
      .sweet-alert input:focus::-webkit-input-placeholder {
        transition: opacity 0.3s 0.03s ease;
        opacity: 0.5; }
    .sweet-alert input::-moz-placeholder {
      color: #bdbdbd; }
    .sweet-alert input:-ms-input-placeholder {
      color: #bdbdbd; }
    .sweet-alert input::-webkit-input-placeholder {
      color: #bdbdbd; }
  .sweet-alert.show-input input {
    display: block; }
  .sweet-alert .sa-confirm-button-container {
    display: inline-block;
    position: relative; }
  .sweet-alert .la-ball-fall {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -27px;
    margin-top: 4px;
    opacity: 0;
    visibility: hidden; }
  .sweet-alert button {
    background-color: #8CD4F5;
    color: white;
    border: none;
    box-shadow: none;
    font-size: 17px;
    font-weight: 500;
    -webkit-border-radius: 4px;
    border-radius: 5px;
    padding: 10px 32px;
    margin: 26px 5px 0 5px;
    cursor: pointer; }
    .sweet-alert button:focus {
      outline: none;
      box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05); }
    .sweet-alert button:hover {
      background-color: #7ecff4; }
    .sweet-alert button:active {
      background-color: #5dc2f1; }
    .sweet-alert button.cancel {
      background-color: #C1C1C1; }
      .sweet-alert button.cancel:hover {
        background-color: #b9b9b9; }
      .sweet-alert button.cancel:active {
        background-color: #a8a8a8; }
      .sweet-alert button.cancel:focus {
        box-shadow: rgba(197, 205, 211, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset !important; }
    .sweet-alert button[disabled] {
      opacity: .6;
      cursor: default; }
    .sweet-alert button.confirm[disabled] {
      color: transparent; }
      .sweet-alert button.confirm[disabled] ~ .la-ball-fall {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s; }
    .sweet-alert button::-moz-focus-inner {
      border: 0; }
  .sweet-alert[data-has-cancel-button=false] button {
    box-shadow: none !important; }
  .sweet-alert[data-has-confirm-button=false][data-has-cancel-button=false] {
    padding-bottom: 40px; }
  .sweet-alert .sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box; }
    .sweet-alert .sa-icon.sa-error {
      border-color: #F27474; }
      .sweet-alert .sa-icon.sa-error .sa-x-mark {
        position: relative;
        display: block; }
      .sweet-alert .sa-icon.sa-error .sa-line {
        position: absolute;
        height: 5px;
        width: 47px;
        background-color: #F27474;
        display: block;
        top: 37px;
        border-radius: 2px; }
        .sweet-alert .sa-icon.sa-error .sa-line.sa-left {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          left: 17px; }
        .sweet-alert .sa-icon.sa-error .sa-line.sa-right {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          right: 16px; }
    .sweet-alert .sa-icon.sa-warning {
      border-color: #F8BB86; }
      .sweet-alert .sa-icon.sa-warning .sa-body {
        position: absolute;
        width: 5px;
        height: 47px;
        left: 50%;
        top: 10px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        margin-left: -2px;
        background-color: #F8BB86; }
      .sweet-alert .sa-icon.sa-warning .sa-dot {
        position: absolute;
        width: 7px;
        height: 7px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        margin-left: -3px;
        left: 50%;
        bottom: 10px;
        background-color: #F8BB86; }
    .sweet-alert .sa-icon.sa-info {
      border-color: #C9DAE1; }
      .sweet-alert .sa-icon.sa-info::before {
        content: "";
        position: absolute;
        width: 5px;
        height: 29px;
        left: 50%;
        bottom: 17px;
        border-radius: 2px;
        margin-left: -2px;
        background-color: #C9DAE1; }
      .sweet-alert .sa-icon.sa-info::after {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-left: -3px;
        top: 19px;
        background-color: #C9DAE1; }
    .sweet-alert .sa-icon.sa-success {
      border-color: #A5DC86; }
      .sweet-alert .sa-icon.sa-success::before, .sweet-alert .sa-icon.sa-success::after {
        content: '';
        -webkit-border-radius: 40px;
        border-radius: 40px;
        border-radius: 50%;
        position: absolute;
        width: 60px;
        height: 120px;
        background: white;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      .sweet-alert .sa-icon.sa-success::before {
        -webkit-border-radius: 120px 0 0 120px;
        border-radius: 120px 0 0 120px;
        top: -7px;
        left: -33px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 60px 60px;
        transform-origin: 60px 60px; }
      .sweet-alert .sa-icon.sa-success::after {
        -webkit-border-radius: 0 120px 120px 0;
        border-radius: 0 120px 120px 0;
        top: -11px;
        left: 30px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 0px 60px;
        transform-origin: 0px 60px; }
      .sweet-alert .sa-icon.sa-success .sa-placeholder {
        width: 80px;
        height: 80px;
        border: 4px solid rgba(165, 220, 134, 0.2);
        -webkit-border-radius: 40px;
        border-radius: 40px;
        border-radius: 50%;
        box-sizing: content-box;
        position: absolute;
        left: -4px;
        top: -4px;
        z-index: 2; }
      .sweet-alert .sa-icon.sa-success .sa-fix {
        width: 5px;
        height: 90px;
        background-color: white;
        position: absolute;
        left: 28px;
        top: 8px;
        z-index: 1;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
      .sweet-alert .sa-icon.sa-success .sa-line {
        height: 5px;
        background-color: #A5DC86;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 2; }
        .sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
          width: 25px;
          left: 14px;
          top: 46px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
        .sweet-alert .sa-icon.sa-success .sa-line.sa-long {
          width: 47px;
          right: 8px;
          top: 38px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }
    .sweet-alert .sa-icon.sa-custom {
      background-size: contain;
      border-radius: 0;
      border: none;
      background-position: center center;
      background-repeat: no-repeat; }

/*
 * Animations
 */
@-webkit-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7); }
  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }
  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7); }
  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }
  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@-webkit-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); } }

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); } }

@-webkit-keyframes slideFromTop {
  0% {
    top: 0%; }
  100% {
    top: 50%; } }

@keyframes slideFromTop {
  0% {
    top: 0%; }
  100% {
    top: 50%; } }

@-webkit-keyframes slideToTop {
  0% {
    top: 50%; }
  100% {
    top: 0%; } }

@keyframes slideToTop {
  0% {
    top: 50%; }
  100% {
    top: 0%; } }

@-webkit-keyframes slideFromBottom {
  0% {
    top: 70%; }
  100% {
    top: 50%; } }

@keyframes slideFromBottom {
  0% {
    top: 70%; }
  100% {
    top: 50%; } }

@-webkit-keyframes slideToBottom {
  0% {
    top: 50%; }
  100% {
    top: 70%; } }

@keyframes slideToBottom {
  0% {
    top: 50%; }
  100% {
    top: 70%; } }

.showSweetAlert[data-animation=pop] {
  -webkit-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s; }

.showSweetAlert[data-animation=none] {
  -webkit-animation: none;
  animation: none; }

.showSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideFromTop 0.3s;
  animation: slideFromTop 0.3s; }

.showSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideFromBottom 0.3s;
  animation: slideFromBottom 0.3s; }

.hideSweetAlert[data-animation=pop] {
  -webkit-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s; }

.hideSweetAlert[data-animation=none] {
  -webkit-animation: none;
  animation: none; }

.hideSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideToTop 0.4s;
  animation: slideToTop 0.4s; }

.hideSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideToBottom 0.3s;
  animation: slideToBottom 0.3s; }

@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s; }

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s; }

.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in; }

@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1; } }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1; } }

.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s; }

@-webkit-keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px; }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px; }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s; }

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #F8D486; }
  100% {
    border-color: #F8BB86; } }

@keyframes pulseWarning {
  0% {
    border-color: #F8D486; }
  100% {
    border-color: #F8BB86; } }

.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate; }

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }

.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate; }

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Internet Explorer 9 has some special quirks that are fixed here */
/* The icons are not animated. */
/* This file is automatically merged into sweet-alert.min.js through Gulp */
/* Error icon */
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg) \9; }

/* Success icon */
.sweet-alert .sa-icon.sa-success {
  border-color: transparent\9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9; }

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-fall,
.la-ball-fall > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff; }

.la-ball-fall.la-dark {
  color: #333; }

.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor; }

.la-ball-fall {
  width: 54px;
  height: 18px; }

.la-ball-fall > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -moz-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite; }

.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms; }

.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms; }

.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms; }

.la-ball-fall.la-sm {
  width: 26px;
  height: 8px; }

.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px; }

.la-ball-fall.la-2x {
  width: 108px;
  height: 36px; }

.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px; }

.la-ball-fall.la-3x {
  width: 162px;
  height: 54px; }

.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px; }

/*
 * Animation
 */
@-webkit-keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    transform: translateY(145%); } }

@-moz-keyframes ball-fall {
  0% {
    opacity: 0;
    -moz-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -moz-transform: translateY(145%);
    transform: translateY(145%); } }

@-o-keyframes ball-fall {
  0% {
    opacity: 0;
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -o-transform: translateY(145%);
    transform: translateY(145%); } }

@keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    -moz-transform: translateY(-145%);
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    -moz-transform: translateY(145%);
    -o-transform: translateY(145%);
    transform: translateY(145%); } }
